import { WppTag } from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import { ComponentProps, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSetState } from 'react-use'

import { AvatarCropEdition } from 'components/common/avatar/avatarCropEdition/AvatarCropEdition'
import { Flex } from 'components/common/flex/Flex'
import { useUploadMasterDataFilesApi } from 'hooks/attachments/useUploadMasterDataFilesApi'
import { excludeFalsy } from 'utils/common'
import { useInMemoryFileUrl } from 'utils/files'

interface Props extends ComponentProps<typeof Flex> {
  name?: string
  tagMessage?: string
  disabled?: boolean
  logoOriginalUrl: MayBeNull<string>
  logoThumbnailUrl: MayBeNull<string>
}

interface State {
  logoThumbnailTempFile: MayBeNull<File>
  logoOriginalTempFile: MayBeNull<File>
  propsOriginalUrl: MayBeNull<string>
  propsThumbnailUrl: MayBeNull<string>
}

export const ManageMasterDataLogoUpload = ({
  name,
  tagMessage,
  disabled,
  logoOriginalUrl,
  logoThumbnailUrl,
  ...rest
}: Props) => {
  const { t } = useTranslation()
  const { setValue } = useFormContext()

  const handleUploadMasterDataFiles = useUploadMasterDataFilesApi()

  const [{ logoThumbnailTempFile, logoOriginalTempFile, propsOriginalUrl, propsThumbnailUrl }, setState] =
    useSetState<State>({
      logoThumbnailTempFile: null,
      logoOriginalTempFile: null,
      propsOriginalUrl: logoOriginalUrl,
      propsThumbnailUrl: logoThumbnailUrl,
    })

  useEffect(() => {
    setState(prevState => ({
      ...prevState,
      propsOriginalUrl: logoOriginalUrl,
      propsThumbnailUrl: logoThumbnailUrl,
    }))
  }, [logoOriginalUrl, logoThumbnailUrl, setState])

  const logoThumbnailTempUrl = useInMemoryFileUrl(logoThumbnailTempFile) || propsThumbnailUrl
  const logoOriginalTempUrl = useInMemoryFileUrl(logoOriginalTempFile) || propsOriginalUrl

  const handleUploadFiles = async ([logoThumbnail, logoOriginal]: File[]) => {
    setState({ logoThumbnailTempFile: logoThumbnail, ...(logoOriginal && { logoOriginalTempFile: logoOriginal }) })
    const [logoThumbnailMetadata, logoOriginalMetadata] = await handleUploadMasterDataFiles(
      [logoThumbnail, logoOriginal].filter(excludeFalsy),
    )

    setValue('logoThumbnail', logoThumbnailMetadata)
    logoOriginalMetadata && setValue('logoOriginal', logoOriginalMetadata)
  }

  const handleDelete = () => {
    setState({
      logoThumbnailTempFile: null,
      logoOriginalTempFile: null,
      propsOriginalUrl: null,
      propsThumbnailUrl: null,
    })
    setValue('logoThumbnail', null)
    setValue('logoOriginal', null)
  }

  return (
    <Flex gap={10} align="center" {...rest}>
      <AvatarCropEdition
        title={t('master_data.fields.logo.edit_logo')}
        avatarName={name}
        avatarSize="l"
        avatarEditorSize={{ width: 504, height: 504 }}
        avatarOriginalUrl={disabled ? null : logoOriginalTempUrl}
        avatarThumbnailUrl={disabled ? null : logoThumbnailTempUrl}
        handleUploadFiles={handleUploadFiles}
        handleDeleteFiles={handleDelete}
        disabled={disabled}
      />
      {tagMessage && <WppTag label={tagMessage} categoricalColorIndex={1} />}
    </Flex>
  )
}
