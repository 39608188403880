import { AutocompleteOption } from '@platform-ui-kit/components-library'
import { forwardRef, useRef, Ref, useImperativeHandle } from 'react'
import { mergeRefs } from 'react-merge-refs'

import { Autocomplete, AutocompleteProps } from 'components/common/autocomplete/Autocomplete'
import { getFieldErrorMessage } from 'components/form/formAutocomplete/utils'
import { useField } from 'hooks/form/useField'
import { routesManager } from 'utils/routesManager'

export type FormAutocompleteProps<T extends AutocompleteOption> = Omit<
  AutocompleteProps<T>,
  'name' | 'value' | 'onChange' | 'onBlur'
> & {
  name: string
  navigationTo?: keyof typeof routesManager.masterData
}

export const FormAutocomplete = forwardRef(function FormAutocomplete<T extends AutocompleteOption>(
  {
    id,
    name,
    message,
    messageType,
    multiple = false,
    onWppChange,
    onWppBlur,
    navigationTo,
    ...rest
  }: Omit<FormAutocompleteProps<T>, 'ref'>,
  ref: Ref<HTMLWppAutocompleteElement>,
) {
  const innerRef = useRef<HTMLWppAutocompleteElement>(null)

  const {
    field: { ref: fieldRef, value, onChange, onBlur },
    fieldState: { isTouched, error },
  } = useField({
    name,
  })

  useImperativeHandle(
    fieldRef,
    () => ({
      focus: () => innerRef.current?.setFocus(),
    }),
    [],
  )

  const identifier = id || name
  const errorText = getFieldErrorMessage(error)
  const shouldShowError = isTouched && !!errorText

  return (
    <Autocomplete
      ref={mergeRefs([innerRef, ref])}
      {...rest}
      id={identifier}
      name={name}
      value={value}
      multiple={multiple}
      message={shouldShowError ? errorText : message}
      messageType={shouldShowError ? 'error' : messageType}
      navigationTo={navigationTo}
      onWppChange={e => {
        onChange(e.detail.value)
        onWppChange?.(e)
      }}
      onWppBlur={e => {
        onBlur()
        onWppBlur?.(e)
      }}
    />
  )
}) as <T extends AutocompleteOption>(props: FormAutocompleteProps<T>) => JSX.Element
